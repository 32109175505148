<template>
  <Skeleton v-if="loading" />
  <div v-else>
    <Header />
    <Banners />
    <Menu />
    <PromoPreview />
  </div>

  <!-- Confirm Button and Total Price -->
  <van-action-bar class="flex gap-4 z-[2999]">
    <van-action-bar-icon
      icon="cart-o"
      :badge="totalQuantity"
      icon-class="!m-0 w-fit translate-x-full"
      @click="showPopup"
    />

    <van-action-bar-button
      type="danger"
      text="Order"
      :disabled="loading ? true : false"
      class="!rounded-none !m-0 !h-full"
      @click="goToConfirmation"
    />
  </van-action-bar>

  <van-popup
    v-model:show="show"
    round
    position="bottom"
    class="!max-h-[80vh] overflow-scroll"
  >
    <van-cell
      class="!sticky top-0 z-[9999]"
      value-class="flex justify-between items-center"
      :border="false"
    >
      <span>Cart ({{ totalQuantity }})</span>
      <van-button
        class="border-0 flex items-center gap-2"
        icon="delete-o"
        @click="emptyCart"
      >
        Clear Cart
      </van-button>
    </van-cell>
    <van-list v-if="selectedItems.length > 0" class="min-h-[80vh] w-full pb-16">
      <div v-for="category in selectedItems" :key="category.categoryName">
        <span
          class="block text-[14px] px-8 py-1 bg-[#F7F8FA] w-[calc(100% + 16px)] -ml-4"
        >
          {{ category.categoryName }}
        </span>

        <van-cell
          v-for="item in category.items"
          :key="item.id"
          class="w-full px-4 pb-4 first:pt-4 bg-white"
        >
          <!-- Price and Quantity -->
          <template #title>
            <div class="flex gap-2">
              <van-image
                class="aspect-square"
                fit="cover"
                width="70"
                height="70"
                :src="item.image"
              />
              <div class="flex flex-col justify-between w-full">
                <div>
                  <p class="text-[15px] font-bold">{{ item.name }}</p>
                  <p class="text-[13px] text-[#999999]">
                    {{ item.description }}
                  </p>
                </div>

                <div class="flex justify-between items-center">
                  <span class="price font-semibold">
                    <span class="text-xs">$</span>
                    <span class="text-xl">{{ Math.floor(item.rate) }}</span>
                    <span class="text-xs"
                      >.{{ (item.rate % 1).toFixed(2).substring(2) }}</span
                    >
                  </span>
                  <van-stepper
                    v-model="item.quantity"
                    button-size="20"
                    disable-input
                    class="flex items-center"
                    min="0"
                  />
                </div>
              </div>
            </div>
          </template>
        </van-cell>
      </div>
    </van-list>
    <van-cell
      v-else
      title="You haven't ordered anything."
      value="Your added items will appear here."
      class="text-black flex flex-col items-center justify-center w-full !py-[25vh]"
      value-class="flex justify-center text-[#999999]"
    />
  </van-popup>
</template>
<script>
import { ref, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import Header from '@/components/Header.vue'
import Banners from '@/components/Banners.vue'
import Menu from '@/components/Menu.vue'
import Skeleton from '@/components/skeletons/Skeleton.vue'
import PromoPreview from '@/components/PromoPreview.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Banners,
    Menu,
    Skeleton,
    PromoPreview,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const orderStore = useOrderStore()

    const show = ref(false)
    const showPopup = () => {
      if (!loading.value) {
        show.value = !show.value
      }
    }

    const emptyCart = () => {
      orderStore.clearCart()
    }

    const goToConfirmation = () => {
      router.push('/confirmation')
    }

    const {
      totalPrice,
      totalQuantity,
      selectedItems,
      updateItemQuantity,
      serverCalled,
      timer,
      restaurant,
      loading,
      tableNumber,
    } = storeToRefs(orderStore)

    const { restaurantName, restaurantLogo, categories } = toRefs(
      restaurant.value
    )

    const {
      loadSupplierData,
      requestHelp,
      loadBanners,
      generateCustomer,
      getCustomerById,
    } = orderStore

    const storedId = ref('')
    const customerIdLocal = ref(null)

    onMounted(async () => {
      const { tableNumber, resId } = route.params

      const storedOrderData = localStorage.getItem('orderStore')
        ? JSON.parse(localStorage.getItem('orderStore'))
        : null

      if (route.params.delivery) {
        restaurant.value.isAcceptDelivery = true
      }

      if (typeof window !== 'undefined') {
        storedId.value = localStorage.getItem(
          `${restaurantName.value}_customerId`
        )

        if (storedId.value) {
          customerIdLocal.value = storedId.value
        }

        if (storedOrderData) {
          orderStore.$patch({
            restaurant: {
              isAcceptDelivery: false, // Update from localStorage
            },
          })
        }
      }

      try {
        await loadSupplierData(resId, tableNumber)
        if (customerIdLocal.value && customerIdLocal.value === storedId.value) {
          getCustomerById(customerIdLocal.value)
        } else {
          generateCustomer()
        }
      } catch (error) {
        console.error('Error loading supplier data or customer info:', error)
      }
    })

    return {
      categories,
      restaurantLogo,
      totalPrice,
      totalQuantity,
      selectedItems,
      show,
      showPopup,
      emptyCart,
      goToConfirmation,
      restaurantName,
      tableNumber,
      updateItemQuantity,
      serverCalled,
      timer,
      loading,
      requestHelp,
      loadBanners,
    }
  },
}
</script>
