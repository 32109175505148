<template>
  <div class="bg-[#c2000030] overflow-hidden">
    <div
      class="relative ml-auto -mr-2 skew-x-[-30deg] bg-[#c20000] w-[calc(100%-33vw)] before:absolute before:inset-0 before:w-2 before:-left-4 before:bg-[#c2000040] after:absolute after:inset-0 after:w-2 after:bg-[#dd6f6f] after:-left-2"
    >
      <p class="text-[10px] text-right pr-6 text-white !skew-x-[30deg]">
        Powered by Wonderpass Technology
      </p>
    </div>
  </div>

  <van-nav-bar class="!bg-[#fafafa]" :border="false" left-arrow>
    <template #right>
      <div class="flex items-center w-[40vw]">
        <van-skeleton-avatar class="flex items-center justify-center" />
        <van-skeleton :row="1" round class="black-row w-full !p-0" />
      </div>
    </template>
  </van-nav-bar>

  <div class="grid grid-cols-[3fr_4fr_2fr] items-center">
    <!-- Skeleton for Menu Title -->
    <van-skeleton class="bg-[#ECF9FF] h-[60px]" />

    <!-- Skeleton for Table Number -->
    <van-skeleton class="bg-[#3388ff] h-[60px]" />

    <!-- Skeleton for Help Button -->
    <van-skeleton class="bg-[#2063c0] h-[60px]" />
  </div>
  <van-skeleton-image image-size="100%" class="!h-20" />

  <div>
    <div class="flex w-full">
      <van-skeleton
        :row="1"
        row-width="100%"
        round
        class="h-[46px] w-24 flex items-center row-mx-auto !p-0"
      />
      <van-skeleton
        :row="1"
        row-width="100%"
        round
        class="h-[46px] w-24 flex items-center row-mx-auto !p-0"
      />
      <van-skeleton
        :row="1"
        row-width="100%"
        round
        class="h-[46px] w-24 flex items-center row-mx-auto !p-0"
      />
      <van-skeleton
        :row="1"
        row-width="100%"
        round
        class="h-[46px] w-24 flex items-center row-mx-auto !p-0"
      />
    </div>
    <div class="h-full">
      <div class="flex gap-2 items-center p-4 border-t-gray-100 border-t-[1px]">
        <van-skeleton-image image-size="70px" class="aspect-square" />
        <div class="flex flex-col gap-2 w-full">
          <van-skeleton :row="1" round row-width="100%" class="!p-0" />
          <van-skeleton
            :row="1"
            row-width="100%"
            round
            class="!p-0 row-full-width"
          />
          <van-skeleton :row="1" round row-width="30%" class="!p-0" />
        </div>
      </div>
      <div class="flex gap-2 items-center p-4 border-t-gray-100 border-t-[1px]">
        <van-skeleton-image image-size="70px" class="aspect-square" />
        <div class="flex flex-col gap-2 w-full">
          <van-skeleton :row="1" round row-width="100%" class="!p-0" />
          <van-skeleton
            :row="1"
            row-width="100%"
            round
            class="!p-0 row-full-width"
          />
          <van-skeleton :row="1" round row-width="30%" class="!p-0" />
        </div>
      </div>
      <div class="flex gap-2 items-center p-4 border-t-gray-100 border-t-[1px]">
        <van-skeleton-image image-size="70px" class="aspect-square" />
        <div class="flex flex-col gap-2 w-full">
          <van-skeleton :row="1" round row-width="100%" class="!p-0" />
          <van-skeleton
            :row="1"
            row-width="100%"
            round
            class="!p-0 row-full-width"
          />
          <van-skeleton :row="1" round row-width="30%" class="!p-0" />
        </div>
      </div>
      <div class="flex gap-2 items-center p-4 border-t-gray-100 border-t-[1px]">
        <van-skeleton-image image-size="70px" class="aspect-square" />
        <div class="flex flex-col gap-2 w-full">
          <van-skeleton :row="1" round row-width="100%" class="!p-0" />
          <van-skeleton
            :row="1"
            row-width="100%"
            round
            class="!p-0 row-full-width"
          />
          <van-skeleton :row="1" round row-width="30%" class="!p-0" />
        </div>
      </div>
      <van-skeleton />
    </div>
  </div>
</template>

<style scoped>
::v-deep .van-skeleton-avatar,
::v-deep .van-skeleton-paragraph {
  background-color: #e3e3e3 !important;
}

.blue-row ::v-deep .van-skeleton-paragraph {
  background-color: #3388ff;
  height: 30px;
  width: 80% !important;
}

.row-mx-auto ::v-deep .van-skeleton-paragraph {
  margin-inline: auto;
}

.row-full-width ::v-deep .van-skeleton-paragraph {
  width: 100% !important;
}

.black-row ::v-deep .van-skeleton-paragraph {
  width: 100% !important;
}
</style>
