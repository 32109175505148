<template>
  <div>
    <van-sidebar
      v-model="activeCategory"
      class="flex gap-3 px-4 !py-2 !w-[100vw] bg-white overflow-scroll"
    >
      <van-button
        type="primary"
        class="!min-w-fit !h-fit text-[13px] !py-1 !px-2 rounded text-[#7d7e80]"
        @click="showPromo = true"
      >
        <van-image
          class="invert"
          fit="contain"
          width="24"
          :src="require('@/assets/images/megaphone.png')"
        />
      </van-button>
      <van-sidebar-item
        v-for="(category, index) in restaurant.categories"
        :key="index"
        :title="category.text"
        :class="[
          '!min-w-fit text-[13px] !py-2 !px-4 rounded !bg-gray-100 text-[#7d7e80]',
          index === activeCategory ? '!text-[#3388ff]' : '',
        ]"
      />
    </van-sidebar>

    <van-list
      class="pb-16 w-full overflow-scroll !bg-white"
      :class="
        restaurant.isSupportBanners && restaurant.banners.length
          ? 'h-[calc(100dvh-279px)]'
          : 'h-[calc(100dvh-199px)]'
      "
    >
      <van-cell
        v-for="item in filteredMenuItems"
        :key="item.id"
        class="w-full px-4 pb-4 first:pt-4 !bg-transparent"
      >
        <!-- Price and Quantity -->
        <template #title>
          <div class="flex gap-2">
            <van-image
              radius="4"
              class="aspect-square min-w-[90px]"
              fit="cover"
              width="90"
              height="90"
              :src="item.image"
              @click="showOverlay(item.image)"
            />
            <van-overlay
              :show="show"
              z-index="9999"
              class="bg-transparent"
              @click="show = false"
            >
              <div class="flex justify-center items-center h-full">
                <van-image
                  :src="currentImage"
                  width="80%"
                  height="80%"
                  fit="contain"
                />
              </div>
            </van-overlay>
            <div class="flex flex-col justify-between w-full">
              <div>
                <p class="text-[15px] font-bold">{{ item.name }}</p>
                <p class="text-[13px] text-[#999999]">
                  {{ item.description }}
                </p>
              </div>

              <div class="flex justify-between items-center">
                <span class="price font-semibold">
                  <span class="text-xs">$</span>
                  <span class="text-xl">{{ Math.floor(item.rate) }}</span>
                  <span class="text-xs"
                    >.{{ (item.rate % 1).toFixed(2).substring(2) }}</span
                  >
                </span>
                <van-stepper
                  v-model="item.quantity"
                  button-size="20"
                  disable-input
                  class="flex items-center"
                  min="0"
                  @change="updateQuantity(item, $event)"
                />
              </div>
            </div>
          </div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useOrderStore } from '@/store/useOrderStore'
import { storeToRefs } from 'pinia'

export default {
  setup() {
    const orderStore = useOrderStore()

    const activeCategory = ref(0)
    const show = ref(false)
    const currentImage = ref('')

    const showOverlay = (image) => {
      currentImage.value = image
      show.value = true
    }

    const filteredMenuItems = computed(() => {
      const category = restaurant.value.categories[activeCategory.value]
      return category ? category.children : []
    })

    const updateQuantity = (item, newQuantity) => {
      orderStore.updateItemQuantity(item, newQuantity)
    }

    const { restaurant, totalQuantity, selectedItems, showPromo } =
      storeToRefs(orderStore)

    return {
      activeCategory,
      restaurant,
      filteredMenuItems,
      totalQuantity,
      selectedItems,
      updateQuantity,
      showOverlay,
      show,
      currentImage,
      showPromo,
    }
  },
}
</script>

<style scoped>
.van-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(64px);
}

.van-sidebar-item--select::before {
  top: unset !important;
  bottom: 0 !important;
  left: 50% !important;
  width: 50% !important;
  height: 3px !important;
  transform: translatex(-50%) !important;
}
</style>
