import { defineStore } from 'pinia'
import { ref, computed, toRefs } from 'vue'
import { fetchSupplierByName } from '@/services/supplierService.js'
import { postSalesOrder } from '@/services/salesOrderService.js'
import { requestSupplierService } from '@/services/supplierService.js'
import {
  addNewCustomer,
  fetchCustomerById,
} from '@/services/customerService.js'
import { useRoute } from 'vue-router'
import { SalesOrderTypeEnum } from '../utils/contants'
import { OrderDTO, RestaurantDTO, CustomerDTO } from '../utils/dtos.js'

export const useOrderStore = defineStore(
  'orderStore',
  () => {
    const createdAt = ref(null)
    const loading = ref(true)
    const storeInitialized = ref(false)
    const error = ref(null)
    const customer = ref(CustomerDTO)
    const showPromo = ref(true)
    const restaurant = ref(RestaurantDTO)
    const tableNumber = ref('')
    const order = ref(OrderDTO)

    const phoneNumber = computed(() => {
      return order.value.salesOrderType === SalesOrderTypeEnum.DELIVER
        ? ''
        : undefined
    })

    const route = useRoute()

    const {
      categories,
      restaurantName,
      restaurantLogo,
      wifiSsid,
      wifiPassword,
      banners,
      promotions,
      isSupportBanners,
      restaurantId,
    } = toRefs(restaurant.value)

    function categoriesMap(data) {
      return data.item_groups.map((group) => {
        const { item_group_name, items } = group

        return {
          text: item_group_name,
          children: items.map((i) => {
            const {
              name,
              item_name,
              item_code,
              description,
              valuation_rate,
              image,
            } = i

            return {
              id: name,
              name: item_name,
              itemCode: item_code,
              description: description,
              quantity: 0,
              rate: valuation_rate,
              image: image,
            }
          }),
        }
      })
    }

    async function loadSupplierData(resId, tableNum) {
      const today = new Date().toISOString().split('T')[0]

      tableNumber.value = tableNum
      error.value = null

      const isDifferentRestaurant = restaurantId.value !== resId
      const storeNotInitialized = !storeInitialized.value
      const isNewOrder =
        order.value.items.length === 0 || createdAt.value !== today

      if (!storeNotInitialized && !isDifferentRestaurant && !isNewOrder) {
        return
      }

      try {
        loading.value = true
        const supplier = await fetchSupplierByName(resId)

        if (supplier) {
          categories.value = categoriesMap(supplier)
          order.value = OrderDTO

          restaurantId.value = resId
          restaurantName.value = supplier.supplier_name
          restaurantLogo.value = supplier.image
          wifiPassword.value = supplier.wifi_password
          wifiSsid.value = supplier.wifi_ssid
          promotions.value = supplier.promotional_schemes[0]
          banners.value = supplier.files
          isSupportBanners.value = supplier.is_support_banner_file

          storeInitialized.value = true
          createdAt.value = today
        } else {
          error.value = 'No supplier found'
        }
      } catch (err) {
        console.log('Error fetching supplier data:', err)
        error.value = 'Error fetching restaurant data'
      } finally {
        loading.value = false
      }
    }

    async function generateCustomer() {
      const { restaurantName } = restaurant.value
      try {
        const response = await addNewCustomer({
          supplierName: restaurantName,
        })

        customer.value = response
        if (customer.value) {
          localStorage.setItem(
            `${restaurantName}_customerId`,
            customer.value.name
          )
        }
      } catch (error) {
        console.error(error)
      }
    }

    async function getCustomerById(id) {
      try {
        const response = await fetchCustomerById(id)
        customer.value = response
      } catch (error) {
        console.error(error)
      }
    }

    async function requestHelp(payload) {
      try {
        const request = await requestSupplierService(payload)
        return request
      } catch (error) {
        console.error(error)
      }
    }

    async function placeOrder(data) {
      try {
        order.value.customerName = customer.value.name
        order.value.tableNumber = tableNumber.value
        order.value.supplierName = restaurant.value.restaurantName
        const confirmation = await postSalesOrder(data)
        return confirmation
      } catch (err) {
        console.error(err)
      }
    }

    function updateOrder(item) {
      const existingItem = order.value.items.find(
        (i) => i.itemCode === item.itemCode
      )
      if (existingItem) {
        existingItem.quantity = item.quantity
      } else {
        order.value.items.push({
          quantity: item.quantity,
          itemCode: item.itemCode,
          rate: item.rate,
        })
      }
    }

    // Computed properties
    const selectedItems = computed(() => {
      const { categories } = toRefs(restaurant.value)
      return categories.value
        .map((category) => {
          const items = category.children.filter((item) => item.quantity > 0)
          return { categoryName: category.text, items }
        })
        .filter((category) => category.items.length > 0)
    })

    const totalPrice = computed(() => {
      const { categories } = toRefs(restaurant.value)
      return categories.value
        .reduce((total, category) => {
          return (
            total +
            category.children.reduce(
              (sum, item) => sum + item.rate * item.quantity,
              0
            )
          )
        }, 0)
        .toFixed(2)
    })

    const totalQuantity = computed(() => {
      const { categories } = toRefs(restaurant.value)
      return categories.value.reduce((total, category) => {
        return (
          total +
          category.children.reduce((sum, item) => sum + item.quantity, 0)
        )
      }, 0)
    })

    // Actions
    const targetUrl = computed(
      () => `${restaurant.value.restaurantId}/${tableNumber.value}`
    )

    const backToHome = () => {
      if (route.fullPath !== targetUrl.value) {
        history.back()
      }
    }

    function updateItemQuantity(item, newQuantity) {
      const { categories } = toRefs(restaurant.value)
      const category = categories.value.find((cat) =>
        cat.children.includes(item)
      )
      if (category) {
        const targetItem = category.children.find((i) => i.id === item.id)
        if (targetItem) {
          targetItem.quantity = newQuantity
          category.children = [...category.children]

          if (newQuantity > 0) {
            updateOrder(targetItem)
          } else {
            removeOrderItem(targetItem)
          }
        }
      }
    }

    function removeOrderItem(item) {
      order.value.items = order.value.items.filter(
        (i) => i.itemCode !== item.itemCode
      )
    }

    function clearCart() {
      const { categories } = toRefs(restaurant.value)
      categories.value.forEach((category) => {
        category.children = category.children.map((item) => ({
          ...item,
          quantity: 0,
        }))
      })

      order.value.items = []
    }

    function onClosePromotion() {
      showPromo.value = false
    }

    return {
      restaurant,
      tableNumber,
      selectedItems,
      totalPrice,
      totalQuantity,
      updateItemQuantity,
      loadSupplierData,
      loading,
      clearCart,
      order,
      placeOrder,
      requestHelp,
      generateCustomer,
      backToHome,
      customer,
      getCustomerById,
      onClosePromotion,
      showPromo,
      phoneNumber,
      createdAt,
    }
  },
  {
    persist: true,
  }
)
