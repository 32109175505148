import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import OrderConfirmationView from '@/views/OrderConfirmationView.vue'

const routes = [
  {
    path: '/:resId/:tableNumber/:delivery?',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: OrderConfirmationView,
  },
]

if (process.env.VUE_APP_NIGHTOUT_ENV === 'development') {
  console.log(process.env.VUE_APP_NIGHTOUT_ENV)
  routes.unshift({
    path: '/',
    redirect: {
      name: 'Home',
      params: { resId: 'SUPP-1728457234490', tableNumber: 'A7357' },
    },
  })
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
