<template>
  <div>
    <Header />
    <van-cell
      title="Order Details"
      class="text-black font-medium !bg-[#F7F8FA] !py-1"
      :border="false"
    />
    <div class="!h-[calc(100dvh-335px)] overflow-scroll">
      <van-list v-if="selectedItems.length > 0" class="w-full">
        <div v-for="category in selectedItems" :key="category.categoryName">
          <van-cell
            v-for="item in category.items"
            :key="item.id"
            class="w-full px-4 pb-4 first:pt-4 bg-white"
          >
            <!-- Price and Quantity -->
            <template #title>
              <div class="flex gap-2">
                <div class="flex items-start justify-between w-full">
                  <p class="text-[15px]">{{ item.name }}</p>

                  <div class="flex gap-8 items-center">
                    <span>x{{ item.quantity }}</span>
                    <span class="price font-semibold"> ${{ item.rate }} </span>
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
        </div>
      </van-list>
      <div v-else class="w-full h-full bg-white flex items-center">
        <van-cell
          title="You haven't ordered anything."
          value="Your added items will appear here."
          class="text-black flex flex-col items-center justify-center"
          value-class="text-[#999999]"
        />
      </div>
    </div>

    <van-cell-group
      class="!absolute !bottom-[80px] left-0 right-0 !m-0 max-w-[100vw] !bg-[#F7F8FA]"
      inset
    >
      <van-field
        v-if="!restaurant.isAcceptDelivery"
        v-model="orderFlowValue"
        is-link
        readonly
        :required="true"
        label="Service"
        placeholder="Choose Service Type"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          title="Choose"
          class="mb-[177px]"
          confirm-button-text="Confirm"
          cancel-button-text="Cancel"
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <van-field
        v-if="restaurant.isAcceptDelivery"
        v-model="order.phoneNumber"
        label="Phone"
        placeholder="Enter your phone number"
        :required="restaurant.isAcceptDelivery ? true : false"
        type="number"
        maxlength="15"
      />
      <van-field
        v-model="order.notes"
        rows="2"
        autosize
        label="Message"
        type="textarea"
        maxlength="100"
        placeholder="Special Requests..."
        show-word-limit
      />
    </van-cell-group>
    <van-loading
      v-if="orderSent"
      class="!absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      color="#1989fa"
    />
  </div>
  <!-- Confirm Button and Total Price -->
  <van-action-bar
    class="z-[2999] pt-2 flex flex-col !h-[80px] border-t-gray-100 border-t-[1px] justify-between absolute bottom-0 left-0 right-0"
  >
    <van-cell
      class="!p-0 !pb-2"
      title-class="font-bold flex justify-between px-4"
    >
      <template #title
        ><span class="text-[#999999] font-normal">Total:</span
        ><span> ${{ totalPrice }}</span></template
      >
    </van-cell>
    <van-action-bar-button
      type="danger"
      text="Place Order"
      class="!rounded-none"
      :disabled="orderSent || totalQuantity === 0 || !orderFlowValue"
      @click="sendOrder(order)"
    />
  </van-action-bar>
</template>
<script>
import { useOrderStore } from '@/store/useOrderStore'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { showDialog } from 'vant'
import Header from '@/components/Header.vue'
import { SalesOrderServingFlowEnum, SalesOrderTypeEnum } from '@/utils/contants'

export default {
  name: 'OrderConfirmationView',
  components: {
    Header,
  },
  setup() {
    const DINE_IN = SalesOrderTypeEnum.DINE_IN
    const DELIVER = SalesOrderTypeEnum.DELIVERY
    const APPETIZERS_FIRST = SalesOrderServingFlowEnum.APPETIZERS_FIRST
    const ALL_AT_ONCE = SalesOrderServingFlowEnum.ALL_AT_ONCE

    const orderStore = useOrderStore()
    const {
      order,
      selectedItems,
      totalPrice,
      totalQuantity,
      tableNumber,
      restaurant,
      phoneNumber,
    } = storeToRefs(orderStore)
    const { placeOrder, clearCart, backToHome } = orderStore

    const orderSent = ref(false)
    const showPicker = ref(false)
    const orderFlowValue = ref('')

    if (order.value && order.value.servingFlow) {
      orderFlowValue.value = replaceHyphensWithSpaces(order.value.servingFlow)
    }

    const columns = [
      { text: 'Appetizers First', value: APPETIZERS_FIRST },
      { text: 'All at Once', value: ALL_AT_ONCE },
    ]

    function replaceHyphensWithSpaces(str) {
      return str.replace(/-/g, ' ')
    }

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false
      order.value.servingFlow = selectedOptions[0].value
      orderFlowValue.value = replaceHyphensWithSpaces(order.value.servingFlow)
    }

    const sendOrder = async (order) => {
      try {
        orderSent.value = true
        const res = await placeOrder(order)

        if (res && res.status === 201) {
          await showDialog({
            message: 'Your order has been placed. Thank you for your patience!',
            confirmButtonText: 'Confirm',
          })
        } else {
          await showDialog({
            message:
              "Sorry, we couldn't complete your order. Please try Again.",
            confirmButtonText: 'Confirm',
          })
        }

        clearCart()
        backToHome()
        orderSent.value = false
      } catch (error) {
        console.error('Error placing order:', error)
      }
    }

    return {
      selectedItems,
      totalPrice,
      totalQuantity,
      tableNumber,
      columns,
      showPicker,
      onConfirm,
      order,
      placeOrder,
      sendOrder,
      orderSent,
      restaurant,
      phoneNumber,
      orderFlowValue,
      DELIVER,
      DINE_IN,
      ALL_AT_ONCE,
    }
  },
}
</script>

<style scoped>
van-checkbox__label {
  margin-left: 0 !important;
}
</style>
